import React, { Component, ReactNode } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { UpsertSettings } from '@wings/shared';
import { IClasses, regex, Utilities, SelectOption } from '@wings-shared/core';
import { observer, inject } from 'mobx-react';
import {
  AvionicsSettingsStore,
  EtpSettingsStore,
  SpeedScheduleSettingsStore,
  SettingsStore,
  SETTING_ID,
  RegistrySequenceBaseModel,
  updateAircraftSidebarOptions,
} from '../Shared';
import { action, observable } from 'mobx';
import { styles } from './Settings.style';
import {
  RegistryIdentifierCountry,
  AircraftModels,
  Series,
  EngineType,
  AerodromeRefCode,
  SubCategory,
  UpsertSettingsProfile,
  FuelReservePolicy,
  AcarsModelComponent,
  AcarsSoftwareVersion,
  FmsModelComponent,
  AcarsMessageSet,
  AesModelComponent,
  FmsSoftwareVersion,
  NoiseChapterConfiguration,
  Transponder,
  CruiseSchedule,
} from './Components';
import { categoryList, settingList } from './Fields';
import { SettingCategoryControl } from '@wings-shared/form-controls';
import { SidebarStore } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  settingsStore?: SettingsStore;
  avionicsSettingsStore?: AvionicsSettingsStore;
  etpSettingsStore?: EtpSettingsStore;
  speedScheduleSettingsStore?: SpeedScheduleSettingsStore;
  sidebarStore?: typeof SidebarStore;
}

@inject('settingsStore', 'avionicsSettingsStore', 'etpSettingsStore', 'speedScheduleSettingsStore', 'sidebarStore')
@observer
class Settings extends Component<Props> {
  @observable private activeCategory: number = 1;
  @observable private activeSubCategory: number = 1;

  componentDidMount() {
    this.props.sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Settings'), 'aircraft');
  }

  @action
  private setActiveCategory(categoryID: number): void {
    this.activeCategory = categoryID;
    this.setActiveSubCategory(this.subCategories[0].value as number);
  }

  @action
  private setActiveSubCategory(categoryID: number): void {
    this.activeSubCategory = categoryID;
  }

  private get subCategories(): SelectOption[] {
    return Utilities.customArraySort(settingList, 'settingLabel')
      .filter(setting => setting.categoryId === this.activeCategory)
      .map(setting => new SelectOption({ name: setting.settingLabel, value: setting.settingId }));
  }

  private renderSetting(): ReactNode {
    const { settingsStore, avionicsSettingsStore, etpSettingsStore, speedScheduleSettingsStore } = this
      .props as Required<Props>;
    switch (this.activeSubCategory) {
      case SETTING_ID.REGISTRY_IDENTIFIER_COUNTRY:
        return <RegistryIdentifierCountry />;
      case SETTING_ID.MODEL:
        return <AircraftModels />;
      case SETTING_ID.ICAO_TYPE_DESIGNATOR:
        return (
          <UpsertSettings
            key="ICAOTypeDesignator"
            type="ICAO Type Designator"
            upsertSettings={data => settingsStore.upsertICAOTypeDesignator(data)}
            getSettings={() => settingsStore.getICAOTypeDesignators()}
            settingsData={settingsStore.icaoTypeDesignators}
            maxLength={4}
            regExp={regex.alphaNumeric}
            headerName="ICAO Type Designator"
            showDeleteButton={true}
            onDelete={data => settingsStore.removeICAOTypeDesignator(data)}
          />
        );
      case SETTING_ID.WAKE_TURBULENCE_CATEGORY:
        return (
          <UpsertSettings
            key="WakeTurbulenceCategory"
            type="Wake Turbulence Category"
            upsertSettings={data => settingsStore.upsertWakeTurbulenceCategory(data)}
            getSettings={() => settingsStore.getWakeTurbulenceCategories()}
            settingsData={settingsStore.wakeTurbulenceCategories}
            maxLength={1}
          />
        );
      case SETTING_ID.NOISE_CHAPTER:
        return (
          <UpsertSettings
            key="NoiseChapter"
            type="Noise Chapter"
            upsertSettings={data => settingsStore.upsertNoiseChapter(data)}
            getSettings={() => settingsStore.getNoiseChapters()}
            settingsData={settingsStore.noiseChapters}
            maxLength={15}
            regExp={regex.all}
            headerName="Chapter"
          />
        );
      case SETTING_ID.FUEL_TYPE:
        return (
          <UpsertSettings
            key="FuelType"
            type="Fuel Type"
            upsertSettings={data => settingsStore.upsertFuelTypeProfile(data)}
            getSettings={() => settingsStore.getFuelTypeProfile()}
            settingsData={settingsStore.fuelTypeProfile}
            maxLength={10}
          />
        );
      case SETTING_ID.FIRE_CATEGORY:
        return (
          <UpsertSettings
            key="FireCategory"
            type="Fire Category"
            upsertSettings={data => settingsStore.upsertFireCategory(data)}
            getSettings={() => settingsStore.getFireCategory()}
            settingsData={settingsStore.fireCategories}
            maxLength={6}
            regExp={regex.all}
            ignoreNumber={true}
          />
        );
      case SETTING_ID.RANGE_UOM:
        return (
          <UpsertSettings
            key="RangeUOM"
            type="Range UOM"
            upsertSettings={data => settingsStore.upsertRangeUOM(data)}
            getSettings={() => settingsStore.getRangeUOMs()}
            settingsData={settingsStore.rangeUOMs}
            maxLength={50}
          />
        );
      case SETTING_ID.WEIGHT_UOM:
        return (
          <UpsertSettings
            key="WeightUOM"
            type="Weight UOM"
            upsertSettings={data => settingsStore.upsertWeightUOM(data)}
            getSettings={() => settingsStore.getWeightUOMs()}
            settingsData={settingsStore.weightUOMs}
            maxLength={50}
          />
        );
      case SETTING_ID.ICAO_AERODROME_REFERENCE_CODE:
        return <AerodromeRefCode />;
      case SETTING_ID.CATEGORIES:
        return (
          <UpsertSettings
            key="Categories"
            type="Categories"
            upsertSettings={data => settingsStore.upsertCategory(data)}
            getSettings={() => settingsStore.getCategories()}
            settingsData={settingsStore.categories}
            maxLength={30}
            regExp={regex.all}
          />
        );
      case SETTING_ID.DISTANCE_UOM:
        return (
          <UpsertSettings
            key="DistanceUOM"
            type="Distance UOM"
            upsertSettings={data => settingsStore.upsertDistanceUOM(data)}
            getSettings={() => settingsStore.getDistanceUOMs()}
            settingsData={settingsStore.distanceUOMs}
            maxLength={50}
          />
        );
      case SETTING_ID.SERIES:
        return <Series />;
      case SETTING_ID.ENGINE_TYPE:
        return <EngineType />;
      case SETTING_ID.ETP_SCENARIO_TYPE:
        return (
          <UpsertSettings
            key="ETPScenarioType"
            type="ETP Scenario Type"
            upsertSettings={data => etpSettingsStore.upsertETPScenarioType(data)}
            getSettings={() => etpSettingsStore.getETPScenarioTypes()}
            settingsData={etpSettingsStore.etpScenarioTypes}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.ETP_TIME_LIMIT:
        return (
          <UpsertSettings
            key="ETPTimeLimitType"
            type="ETP Time Limit Type"
            upsertSettings={data => etpSettingsStore.upsertETPTimeLimitType(data)}
            getSettings={() => etpSettingsStore.getETPTimeLimitTypes()}
            settingsData={etpSettingsStore.etpTimeLimitTypes}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.AIRCRAFT_MODIFICATION:
        return (
          <UpsertSettings
            key="aircraftModification"
            type="Aircraft Modification"
            upsertSettings={data => settingsStore.upsertAircraftModification(data)}
            getSettings={() => settingsStore.getAircraftModifications()}
            settingsData={settingsStore.aircraftModifications}
            maxLength={50}
            headerName="Modification"
            regExp={regex.all}
          />
        );
      case SETTING_ID.ETP_ALT_DESCENT_PROFILE:
        return (
          <UpsertSettings
            key="ETPAltDescentProfile"
            type="ETP Alt Descent Profile"
            upsertSettings={data => etpSettingsStore.upsertETPAltDescent(data)}
            getSettings={() => etpSettingsStore.getETPAltDescents()}
            settingsData={etpSettingsStore.etpAltDescentProfiles}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_SCENARIO_ENGINE:
        return (
          <UpsertSettings
            key="ETPScenarioEngine"
            type="ETP Scenario Engine"
            upsertSettings={data => etpSettingsStore.upsertETPScenarioEngine(data)}
            getSettings={() => etpSettingsStore.getETPScenarioEngines()}
            settingsData={etpSettingsStore.ETPScenarioEngines}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.ETP_LEVELS:
        return (
          <UpsertSettings
            key="ETPLevels"
            type="ETP Levels"
            upsertSettings={data => etpSettingsStore.upsertETPLevel(data)}
            getSettings={() => etpSettingsStore.getETPLevels()}
            settingsData={etpSettingsStore.ETPLevels}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_MAIN_DESCENT:
        return (
          <UpsertSettings
            key="ETPMainDescent"
            type="ETP Main Descent"
            upsertSettings={data => etpSettingsStore.upsertETPMainDescent(data)}
            getSettings={() => etpSettingsStore.getETPMainDescents()}
            settingsData={etpSettingsStore.ETPMainDescents}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_FINAL_DESCENT:
        return (
          <UpsertSettings
            key="ETPFinalDescent"
            type="ETP Final Descent"
            upsertSettings={data => etpSettingsStore.upsertETPFinalDescent(data)}
            getSettings={() => etpSettingsStore.getETPFinalDescents()}
            settingsData={etpSettingsStore.ETPFinalDescents}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_CRUISE_PROFILE:
        return (
          <UpsertSettings
            key="ETPCruiseProfile"
            type="ETP Cruise Profile"
            upsertSettings={data => etpSettingsStore.upsertETPCruiseProfile(data)}
            getSettings={() => etpSettingsStore.getETPCruiseProfiles()}
            settingsData={etpSettingsStore.ETPCruiseProfiles}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_HOLD_METHOD:
        return (
          <UpsertSettings
            key="ETPHoldMethod"
            type="ETP Hold Method"
            upsertSettings={data => etpSettingsStore.upsertETPHoldMethod(data)}
            getSettings={() => etpSettingsStore.getETPHoldMethods()}
            settingsData={etpSettingsStore.ETPHoldMethods}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_PENALTY_BIAS_TYPE:
        return (
          <UpsertSettings
            key="ETPPenaltyBiasType"
            type="ETP Penalty Bias Type"
            upsertSettings={data => etpSettingsStore.upsertETPPenaltyBias(data)}
            getSettings={() => etpSettingsStore.getETPPenaltyBias()}
            settingsData={etpSettingsStore.ETPPenaltyBias}
            maxLength={200}
          />
        );
      case SETTING_ID.FLIGHT_PLAN_FORMAT_STATUS:
        return (
          <UpsertSettings
            key="FlightPlanFormatStatus"
            type="Flight Plan Format Status"
            upsertSettings={data => settingsStore.upsertFlightPlanFormatStatus(data)}
            getSettings={() => settingsStore.getFlightPlanFormatStatus()}
            settingsData={settingsStore.flightPlanFormatStatus}
            maxLength={200}
          />
        );
      case SETTING_ID.SUB_CATEGORY:
        return <SubCategory />;
      case SETTING_ID.ETP_APU_BURN_METHOD:
        return (
          <UpsertSettings
            key="ETPAPUBurnMethod"
            type="ETP APU Burn Method"
            upsertSettings={data => etpSettingsStore.upsertETPAPUBurnMethod(data)}
            getSettings={() => etpSettingsStore.getETPAPUBurnMethods()}
            settingsData={etpSettingsStore.ETPAPUBurnMethods}
            maxLength={50}
          />
        );
      case SETTING_ID.ETP_PENALTY_APPLY:
        return (
          <UpsertSettings
            key="ETPPenaltyApply"
            type="ETP Penalty Apply"
            upsertSettings={data => etpSettingsStore.upsertETPPenaltyApply(data)}
            getSettings={() => etpSettingsStore.getETPPenaltyApply()}
            settingsData={etpSettingsStore.ETPPenaltyApply}
            maxLength={50}
          />
        );
      case SETTING_ID.AIRCRAFT_COLOR:
        return (
          <UpsertSettings
            key="AircraftColor"
            type="Aircraft Color"
            upsertSettings={data => settingsStore.upsertAircraftColor(data)}
            getSettings={() => settingsStore.getAircraftColors()}
            settingsData={settingsStore.aircraftColors}
            maxLength={15}
          />
        );
      case SETTING_ID.AIRFRAME_STATUS:
        return (
          <UpsertSettings
            key="AirframeStatus"
            type="Airframe Status"
            upsertSettings={data => settingsStore.upsertAirframeStatus(data)}
            getSettings={() => settingsStore.getAirframeStatus()}
            settingsData={settingsStore.airframeStatus}
            maxLength={30}
          />
        );
      case SETTING_ID.ETP_PENALTY_CATEGORY:
        return (
          <UpsertSettings
            key="ETPPenaltyCategory"
            type="ETP Penalty Category"
            upsertSettings={data => etpSettingsStore.upsertETPPenaltyCategory(data)}
            getSettings={() => etpSettingsStore.getETPPenaltyCategories()}
            settingsData={etpSettingsStore.ETPPenaltyCategories}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.CRUISE_SCHEDULE:
        return <CruiseSchedule />;
      case SETTING_ID.AIRCRAFT_MAKE:
        return (
          <UpsertSettings
            key="Make"
            type="Make"
            headerName="Make"
            upsertSettings={data => settingsStore.upsertMake(data)}
            getSettings={() => settingsStore.getMakes()}
            settingsData={settingsStore.makes}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.WIND_UOM:
        return (
          <UpsertSettings
            key="WindUOM"
            type="Wind UOM"
            upsertSettings={data => settingsStore.upsertWindUOM(data)}
            getSettings={() => settingsStore.getWindUOMs()}
            settingsData={settingsStore.windUOMs}
            maxLength={50}
            regExp={regex.all}
          />
        );
      case SETTING_ID.CLIMB_SCHEDULE:
        return (
          <UpsertSettingsProfile
            key="climbSchedule"
            typeKey="climbSchedule"
            type="Climb Schedule"
            upsertSettings={data => speedScheduleSettingsStore.upsertClimbSchedule(data)}
            getSettings={() => speedScheduleSettingsStore.getClimbSchedules()}
          />
        );
      case SETTING_ID.FUEL_RESERVE_POLICY:
        return <FuelReservePolicy />;
      case SETTING_ID.SOURCE_TYPE:
        return (
          <UpsertSettings
            key="SourceType"
            type="Source Type"
            upsertSettings={data => settingsStore.upsertSourceType(data)}
            getSettings={() => settingsStore.getSourceTypes()}
            settingsData={settingsStore.sourceTypes}
            maxLength={100}
            sortColumn="name"
          />
        );
      case SETTING_ID.ACCESS_LEVEL:
        return (
          <UpsertSettings
            key="AccessLevel"
            type="Access Level"
            upsertSettings={data => settingsStore.upsertAccessLevel(data)}
            getSettings={() => settingsStore.getAccessLevels()}
            settingsData={settingsStore.accessLevels}
            maxLength={50}
            sortColumn="name"
          />
        );
      case SETTING_ID.DESCENT_SCHEDULE:
        return (
          <UpsertSettingsProfile
            key="descentSchedule"
            typeKey="descentSchedule"
            type="Descent Schedule"
            upsertSettings={data => speedScheduleSettingsStore.upsertDescentSchedule(data)}
            getSettings={() => speedScheduleSettingsStore.getDescentSchedules()}
          />
        );
      case SETTING_ID.ACARS_MANUFACTURER:
        return (
          <UpsertSettings
            key="ACARSManufacturer"
            type="ACARS Manufacturer"
            upsertSettings={data => avionicsSettingsStore.upsertAcarsManufacturer(data)}
            getSettings={() => avionicsSettingsStore.getAcarsManufacturers()}
            settingsData={avionicsSettingsStore.acarsManufacturers}
            maxLength={50}
            sortColumn="name"
          />
        );
      case SETTING_ID.HOLD_SCHEDULE:
        return (
          <UpsertSettingsProfile
            key="holdSchedule"
            typeKey="holdSchedule"
            type="Hold Schedule"
            upsertSettings={data => speedScheduleSettingsStore.upsertHoldSchedule(data)}
            getSettings={() => speedScheduleSettingsStore.getHoldSchedules()}
          />
        );
      case SETTING_ID.ACARS_MODEL:
        return <AcarsModelComponent />;
      case SETTING_ID.FMS_MANUFACTURER:
        return (
          <UpsertSettings
            key="FMSManufacturer"
            type="FMS Manufacturer"
            upsertSettings={data => avionicsSettingsStore.upsertFmsManufacturer(data)}
            getSettings={() => avionicsSettingsStore.getFmsManufacturers()}
            settingsData={avionicsSettingsStore.fmsManufacturers}
            maxLength={50}
            sortColumn="name"
          />
        );
      case SETTING_ID.ACARS_SOFTWARE_VERSION:
        return <AcarsSoftwareVersion />;
      case SETTING_ID.AES_MANUFACTURER:
        return (
          <UpsertSettings
            key="AESManufacturer"
            type="AES Manufacturer"
            upsertSettings={data => avionicsSettingsStore.upsertAesManufacturer(data)}
            getSettings={() => avionicsSettingsStore.getAesManufacturers()}
            settingsData={avionicsSettingsStore.aesManufacturers}
            maxLength={50}
          />
        );
      case SETTING_ID.FMS_MODEL:
        return <FmsModelComponent />;
      case SETTING_ID.ACARS_MESSAGE_SET:
        return <AcarsMessageSet />;
      case SETTING_ID.AES_MODEL:
        return <AesModelComponent />;
      case SETTING_ID.RAIM_REPORT_TYPE:
        return (
          <UpsertSettings
            key="RAIMReportType"
            type="RAIM Report Type"
            upsertSettings={data => avionicsSettingsStore.upsertRaimReportType(data)}
            getSettings={() => avionicsSettingsStore.getRaimReportTypes()}
            settingsData={avionicsSettingsStore.raimReportTypes}
            isNameUnique={true}
            maxLength={50}
            sortColumn="name"
          />
        );
      case SETTING_ID.FMS_SOFTWARE_VERSION:
        return <FmsSoftwareVersion />;
      case SETTING_ID.NFP_FUEL_RESERVE_TYPE:
        return (
          <UpsertSettings
            key="NFPFuelReserveType"
            type="NFP Fuel Reserve Type"
            isNameUnique={true}
            upsertSettings={data => avionicsSettingsStore.upsertNfpFuelReserveType(data)}
            getSettings={() => avionicsSettingsStore.getNfpFuelReserveTypes()}
            settingsData={avionicsSettingsStore.nfpFuelReserveTypes}
            maxLength={50}
          />
        );
      case SETTING_ID.RAIM_RECEIVER_TYPE:
        return (
          <UpsertSettings
            key="RAIMReceiverType"
            type="RAIM Receiver Type"
            upsertSettings={data => avionicsSettingsStore.upsertRaimReceiverType(data)}
            getSettings={() => avionicsSettingsStore.getRaimReceiverTypes()}
            settingsData={avionicsSettingsStore.raimReceiverTypes}
            isNameUnique={true}
            maxLength={50}
            regExp={regex.all}
            sortColumn="name"
          />
        );
      case SETTING_ID.STC_MANUFACTURE:
        return (
          <UpsertSettings
            key="stcManufactre"
            type="STC Manufacture"
            upsertSettings={data => settingsStore.upsertStcManufacture(data)}
            getSettings={() => settingsStore.getStcManufactures()}
            settingsData={settingsStore.stcManufactures}
            regExp={regex.all}
            headerName="STC Manufacture"
          />
        );
      case SETTING_ID.AIRCRAFT_NOISE_TYPE:
        return (
          <UpsertSettings
            key="AircraftNoiseType"
            type="Aircraft Noise Type"
            upsertSettings={data => settingsStore.upsertAircraftNoiseType(data)}
            getSettings={() => settingsStore.getAircraftNoiseTypes()}
            settingsData={settingsStore.aircraftNoiseTypes}
            regExp={regex.all}
            isNameUnique={true}
            maxLength={50}
          />
        );
      case SETTING_ID.NOISE_DATA_TYPE_CERTIFICATION:
        return (
          <UpsertSettings
            key="NoiseDateTypeCertification"
            type="Noise Date Type Certification"
            upsertSettings={data => settingsStore.upsertNoiseDateTypeCertification(data)}
            getSettings={() => settingsStore.getNoiseDateTypeCertifications()}
            settingsData={settingsStore.noiseDateTypeCertifications}
            regExp={regex.all}
            isNameUnique={true}
            maxLength={50}
          />
        );
      case SETTING_ID.RADIO:
        return (
          <UpsertSettings<RegistrySequenceBaseModel>
            key="radio"
            type="Radio"
            upsertSettings={data => settingsStore.upsertRadio(data)}
            getSettings={() => settingsStore.getRadios()}
            settingsData={settingsStore.radios}
            regExp={regex.all}
            isNameUnique={true}
            maxLength={50}
          />
        );
      case SETTING_ID.ACAS:
        return (
          <UpsertSettings
            key="ACAS"
            type="ACAS"
            upsertSettings={data => settingsStore.upsertAcas(data)}
            getSettings={() => settingsStore.getAcases()}
            settingsData={settingsStore.acases}
            regExp={regex.all}
            isNameUnique={true}
            maxLength={50}
          />
        );
      case SETTING_ID.NOISE_CHAPTER_CONFIGURATION:
        return <NoiseChapterConfiguration />;
      case SETTING_ID.TRANSPONDER:
        return <Transponder />;
      case SETTING_ID.MILITARY_DESIGNATION:
        return (
          <UpsertSettings
            key="militaryDesignation"
            type="Military Designation"
            upsertSettings={data => settingsStore.upsertMilitaryDesignation(data)}
            getSettings={() => settingsStore.getMilitaryDesignations()}
            settingsData={settingsStore.militaryDesignations}
            maxLength={50}
            regExp={regex.all}
            isNameUnique={true}
          />
        );
      case SETTING_ID.OTHER_NAME:
        return (
          <UpsertSettings
            key="otherName"
            type="Other Name"
            upsertSettings={data => settingsStore.upsertOtherName(data)}
            getSettings={() => settingsStore.getOtherNames()}
            settingsData={settingsStore.otherNames}
            regExp={regex.all}
            isNameUnique={true}
            ignoreNumber={true}
            maxLength={100}
          />
        );
      case SETTING_ID.WAKE_TURBULENCE_GROUP:
        return (
          <UpsertSettings
            key="wakeTurbulenceGroup"
            type="Wake Turbulence Group"
            upsertSettings={data => settingsStore.upsertWakeTurbulenceGroup(data)}
            getSettings={() => settingsStore.getWakeTurbulenceGroups()}
            settingsData={settingsStore.wakeTurbulenceGroups}
            regExp={regex.all}
            isNameUnique={true}
            ignoreNumber={true}
            maxLength={1}
          />
        );
      case SETTING_ID.POPULAR_NAME:
        return (
          <UpsertSettings
            key="popularName"
            type="Popular Name"
            upsertSettings={data => settingsStore.upsertPopularName(data)}
            getSettings={() => settingsStore.getPopularNames()}
            settingsData={settingsStore.popularNames}
            regExp={regex.all}
            isNameUnique={true}
            ignoreNumber={true}
            maxLength={100}
          />
        );
      case SETTING_ID.FLIGHT_PLANNING_SERVICE_TYPE:
        return (
          <UpsertSettings
            key="flightPlanningServiceType"
            type="Type"
            upsertSettings={data => settingsStore.upsertFlightPlanningServiceType(data)}
            getSettings={() => settingsStore.getFlightPlanningServiceTypes()}
            settingsData={settingsStore.flightPlanningServiceTypes}
            regExp={regex.all}
            isNameUnique={true}
            ignoreNumber={true}
            maxLength={50}
            headerName="Type"
          />
        );
      case SETTING_ID.DELIVERY_PACKAGE:
        return (
          <UpsertSettings
            key="deliveryPackage"
            type="Delivery Package"
            upsertSettings={data => settingsStore.upsertDeliveryPackageType(data)}
            getSettings={() => settingsStore.getDeliveryPackageType()}
            settingsData={settingsStore.flightPlanningServiceTypes}
            regExp={regex.all}
            isNameUnique={true}
            ignoreNumber={true}
            maxLength={50}
            headerName="Delivery Package Type"
          />
        );
      case SETTING_ID.RUNWAY_ANALYSIS:
        return (
          <UpsertSettings
            key="runwayAnalysis"
            type="Runway Analysis"
            upsertSettings={data => settingsStore.upsertRunwayAnalysisType(data)}
            getSettings={() => settingsStore?.getRunwayAnalysisType()}
            settingsData={settingsStore?.runwayAnalysisType}
            regExp={regex.all}
            isNameUnique={true}
            ignoreNumber={true}
            maxLength={50}
            headerName="Runway Analysis Type"
          />
        );

      default:
        return null;
    }
  }

  public render() {
    const { classes } = this.props as Required<Props>;
    return (
      <>
        <div className={classes.heading}>
          <Typography variant="h5">Aircraft Settings</Typography>
        </div>
        <div className={classes.root}>
          <div className={classes.selectSettingContainer}>
            <SettingCategoryControl
              title="Category"
              value={this.activeCategory}
              selectOptions={categoryList}
              onOptionChange={category => this.setActiveCategory(category)}
            />
            <SettingCategoryControl
              title="Sub category"
              value={this.activeSubCategory}
              selectOptions={this.subCategories}
              onOptionChange={settingLabel => this.setActiveSubCategory(settingLabel)}
            />
          </div>
          <div className={classes.settingWrapper}>{this.renderSetting()}</div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Settings);
export { Settings as PureSettings };
