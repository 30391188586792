import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { ColDef, GridOptions, ValueFormatterParams, ICellEditorParams } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { Logger, SettingsModuleSecurity } from '@wings-shared/security';
import { inject, observer } from 'mobx-react';
import { NoiseChapterConfigurationModel, NOISE_CHAPTER_CONFIGURATION_TYPE, SettingsStore } from '../../../Shared';
import { AlertStore } from '@uvgo-shared/alert';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { UIStore, Utilities, GRID_ACTIONS, SettingsTypeModel } from '@wings-shared/core';
import { CustomAgGridReact, useAgGrid, useGridState, agGridUtilities } from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { forkJoin } from 'rxjs';

interface Props {
  settingsStore?: SettingsStore;
}

const NoiseChapterConfigurationV2: FC<Props> = ({ settingsStore }) => {
  const alertMessageId: string = 'NoiseChapterConfiguration';
  const unsubscribe = useUnsubscribe();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const gridState = useGridState();
  const agGrid = useAgGrid<NOISE_CHAPTER_CONFIGURATION_TYPE, NoiseChapterConfigurationModel>([], gridState);
  const _settingsStore = settingsStore as SettingsStore;

  /* istanbul ignore next */
  useEffect(() => {
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    forkJoin([
      _settingsStore.getNoiseChapterConfigurations(),
      _settingsStore.getNoiseChapters(),
      _settingsStore.getNoiseDateTypeCertifications(),
      _settingsStore.getAircraftNoiseTypes(),
    ])
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(([ noiseChapterConfigurations ]) => gridState.setGridData(noiseChapterConfigurations));
  };

  const addNewType = () => {
    agGrid.addNewItems(
      [
        new NoiseChapterConfigurationModel({
          id: 0,
        }),
      ],
      {
        startEditing: false,
        colKey: 'noiseChapter',
      }
    );
    gridState.setHasError(true);
  };

  const isAlreadyExists = (id: number): boolean => {
    if (agGrid._isAlreadyExists([ 'noiseDateTypeCertification', 'aircraftNoiseType', 'noiseChapter' ], id)) {
      agGrid.showAlert('Noise Chapter, Noise Type and Noise Date Type Certification should be unique.', alertMessageId);
      return true;
    }
    return false;
  };

  const gridActions = (gridAction: GRID_ACTIONS, rowIndex: number): void => {
    if (rowIndex === null) {
      return;
    }
    switch (gridAction) {
      case GRID_ACTIONS.EDIT:
        agGrid._startEditingCell(rowIndex, columnDefs[0].field || '');
        break;
      case GRID_ACTIONS.SAVE:
        upsertRegistryIdentifierCountry(rowIndex);
        break;
      case GRID_ACTIONS.CANCEL:
      default:
        agGrid.cancelEditing(rowIndex);
        break;
    }
  };

  /* istanbul ignore next */
  const upsertRegistryIdentifierCountry = (rowIndex: number): void => {
    const model = agGrid._getTableItem(rowIndex);
    if (isAlreadyExists(model.id)) {
      return;
    }
    gridState.gridApi.stopEditing();
    UIStore.setPageLoader(true);
    _settingsStore
      .upsertNoiseChapterConfiguration(model)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: NoiseChapterConfigurationModel) => agGrid._updateTableItem(rowIndex, response),
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
          Logger.error(error.message);
        },
      });
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Noise Chapter',
      field: 'noiseChapter',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Noise Chapter',
        getAutoCompleteOptions: () => _settingsStore.noiseChapters,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Noise Type',
      field: 'aircraftNoiseType',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Noise Type',
        getAutoCompleteOptions: () => _settingsStore.aircraftNoiseTypes,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      headerName: 'Noise Date Type Certification',
      field: 'noiseDateTypeCertification',
      cellEditor: 'customAutoComplete',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      cellEditorParams: {
        isRequired: true,
        placeHolder: 'Noise Date Type Certification',
        getAutoCompleteOptions: () => _settingsStore.noiseDateTypeCertifications,
        valueGetter: (option: SettingsTypeModel) => option,
      },
    },
    {
      ...agGrid.actionColumn({
        minWidth: 150,
        maxWidth: 210,
        hide: !SettingsModuleSecurity.isEditable,
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {
        onInputChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
        onDropDownChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
      },
      columnDefs,
      isEditable: SettingsModuleSecurity.isEditable,
      gridActionProps: {
        showDeleteButton: false,
        getDisabledState: () => gridState.hasError,
        onAction: (action: GRID_ACTIONS, rowIndex: number) => gridActions(action, rowIndex),
      },
    });
    return {
      ...baseOptions,
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        const {
          id,
          noiseChapter,
          noiseDateTypeCertification,
          aircraftNoiseType,
        } = node.data as NoiseChapterConfigurationModel;
        if (!searchHeader) {
          return false;
        }
        return (
          !id ||
          agGrid.isFilterPass(
            {
              [NOISE_CHAPTER_CONFIGURATION_TYPE.CHAPTER]: noiseChapter.name,
              [NOISE_CHAPTER_CONFIGURATION_TYPE.TYPE]: aircraftNoiseType.name,
              [NOISE_CHAPTER_CONFIGURATION_TYPE.CERTIFICATION]: noiseDateTypeCertification.name,
            },
            searchHeader.searchValue,
            searchHeader.selectedOption
          )
        );
      },
    };
  };

  const rightContent = (): ReactNode => {
    if (!SettingsModuleSecurity.isEditable) {
      return null;
    }
    return (
      <PrimaryButton
        variant="contained"
        startIcon={<AddIcon />}
        disabled={gridState.isRowEditing || UIStore.pageLoading}
        onClick={addNewType}
      >
        Add Noise Chapter Configuration
      </PrimaryButton>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        // eslint-disable-next-line max-len
        selectInputs={[
          agGridUtilities.createSelectOption(
            NOISE_CHAPTER_CONFIGURATION_TYPE,
            NOISE_CHAPTER_CONFIGURATION_TYPE.CHAPTER
          ),
        ]}
        onFilterChange={() => gridState.gridApi.onFilterChanged()}
        rightContent={rightContent}
        disableControls={gridState.isRowEditing}
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        disablePagination={gridState.isRowEditing}
      />
    </>
  );
};

export default inject('settingsStore')(observer(NoiseChapterConfigurationV2));
