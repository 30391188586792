import React, { FC, ReactNode, RefObject, useEffect, useRef } from 'react';
import { ColDef, GridOptions, ValueFormatterParams, ValueGetterParams, FilterChangedEvent } from 'ag-grid-community';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { inject, observer } from 'mobx-react';
import { AlertStore } from '@uvgo-shared/alert';
import { finalize, takeUntil } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import {
  UIStore,
  Utilities,
  GRID_ACTIONS,
  IClasses,
  SettingsTypeModel,
  SearchStore,
  ViewPermission,
  IAPIGridRequest,
  IAPIPageResponse,
  GridPagination,
} from '@wings-shared/core';
import { CustomAgGridReact, useAgGrid, useGridState, agGridUtilities } from '@wings-shared/custom-ag-grid';
import { useConfirmDialog, useUnsubscribe } from '@wings-shared/hooks';
import {
  AIRCRAFT_VARIATION_FILTERS,
  AircraftModuleSecurity,
  AircraftVariationModel,
  AircraftVariationStore,
  updateAircraftSidebarOptions,
} from '../Shared';
import { VIEW_MODE } from '@wings/shared';
import { CustomLinkButton, SidebarStore } from '@wings-shared/layout';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { aircraftVariationGridFilters } from './fields';

interface Props {
  classes?: IClasses;
  aircraftVariationStore?: AircraftVariationStore;
  sidebarStore?: typeof SidebarStore;
}

const AircraftVariationV2: FC<Props> = ({ aircraftVariationStore, sidebarStore }) => {
  const unsubscribe = useUnsubscribe();
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const gridState = useGridState();
  const agGrid = useAgGrid<AIRCRAFT_VARIATION_FILTERS, AircraftVariationModel>([], gridState);
  const _aircraftVariationStore = aircraftVariationStore as AircraftVariationStore;
  const _useConfirmDialog = useConfirmDialog();

  /* istanbul ignore next */
  useEffect(() => {
    sidebarStore?.setNavLinks(updateAircraftSidebarOptions('Aircraft Variation'), 'aircraft');
    // Restore Search Result based on available history
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData) {
      gridState.setPagination(searchData.pagination);
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      /* istanbul ignore next */
      SearchStore.clearSearchData(location.pathname);
      return;
    }
    loadInitialData();
    agGrid.filtersApi.onAdvanceFilterChange$.subscribe(() => loadInitialData());
  }, []);

  const onAction = (action: GRID_ACTIONS, rowIndex: number): void => {
    if (Utilities.isEqual(action, GRID_ACTIONS.DELETE)) {
      confirmDeleteAction(rowIndex);
    }
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Popular Names',
      field: 'popularNames',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('popularNames', 2),
      cellRenderer: 'agGridChipView',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.name,
      filterValueGetter: ({ data }: ValueGetterParams) => data.popularNames?.map(v => v.label).join(', ') || '',
    },
    {
      headerName: 'Make',
      field: 'make',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('make', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.make.name,
    },
    {
      headerName: 'Model',
      field: 'model',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('model', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.model.name,
    },
    {
      headerName: 'Series',
      field: 'series',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('series', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.series.name,
    },
    {
      headerName: 'Engine Type',
      field: 'engineType',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('engineType', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.engineType.name,
    },
    {
      headerName: 'ICAO Type Designator',
      field: 'icaoTypeDesignator',
      comparator: (current: SettingsTypeModel, next: SettingsTypeModel) =>
        Utilities.customComparator(current, next, 'name'),
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
      filter: 'agTextColumnFilter',
      filterParams: agGrid.filtersApi.getAdvanceFilterParams('icaoTypeDesignator', 2),
      filterValueGetter: ({ data }: ValueGetterParams) => data.icaoTypeDesignator?.label,
    },
    ...agGrid.auditFieldsWithAdvanceFilter(gridState.isRowEditing),
    {
      headerName: 'Action',
      cellRenderer: 'actionRenderer',
      cellEditor: 'actionRenderer',
      sortable: false,
      filter: false,
      minWidth: 150,
      maxWidth: 210,
      suppressSizeToFit: true,
      cellRendererParams: {
        isActionMenu: true,
        actionMenus: () => [
          {
            title: 'Edit',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.EDIT,
            to: node => `/aircraft/aircraft-variation/${node?.data.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
          },
          {
            title: 'Details',
            action: GRID_ACTIONS.DETAILS,
            to: node => `/aircraft/aircraft-variation/${node?.data.id}/${VIEW_MODE.DETAILS.toLowerCase()}`,
          },
          {
            title: 'Delete',
            isHidden: !AircraftModuleSecurity.isEditable,
            action: GRID_ACTIONS.DELETE,
          },
        ],
        onAction: (action: GRID_ACTIONS, rowIndex: number) => {
          if (searchHeaderRef.current?.searchValue) {
            const clientSearchValue = {
              selectedOption: searchHeaderRef.current?.selectedOption,
              searchValue: searchHeaderRef.current?.searchValue as string,
            };
            SearchStore.setclientSearchValue(clientSearchValue);
          }
          onAction(action, rowIndex);
        },
      },
    },
  ];

  /* istanbul ignore next */
  const deleteAircraftVariationRecord = (model: AircraftVariationModel): void => {
    UIStore.setPageLoader(true);
    _aircraftVariationStore
      .deleteAircraftVariationRecord(model.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: response => {
          agGrid._removeTableItems([ model ]);
          gridState.setGridData(gridState.data.filter(({ id }) => model.id !== id));
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  };

  /* istanbul ignore next */
  const confirmDeleteAction = (rowIndex: number): void => {
    const model: AircraftVariationModel = agGrid._getTableItem(rowIndex);
    _useConfirmDialog.confirmAction(
      () => {
        deleteAircraftVariationRecord(model), ModalStore.close();
      },
      {
        title: 'Confirm Delete',
        isDelete: true,
      }
    );
  };

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {
        onInputChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
        onDropdownChange: () => gridState.setHasError(Utilities.hasInvalidRowData(gridState.gridApi)),
      },
      columnDefs,
      isEditable: false,
      gridActionProps: {
        tooltip: 'Aircraft Variation',
        getDisabledState: () => gridState.hasError,
      },
    });

    return {
      ...baseOptions,
      pagination: false,
      isExternalFilterPresent: () => false,
      onFilterChanged: (events: FilterChangedEvent) => {
        Array.from(gridState.columFilters).length === 0 && loadInitialData({ pageNumber: 1 });
      },
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadInitialData({ pageNumber: 1 });
      },
    };
  };

  /* istanbul ignore next */
  const getFilterCollection = (): IAPIGridRequest => {
    if (!searchHeaderRef.current || !searchHeaderRef.current.searchValue) {
      return {};
    }
    const property = aircraftVariationGridFilters.find(({ uiFilterType }) =>
      Utilities.isEqual(uiFilterType as string, searchHeaderRef.current?.selectedOption)
    );
    return {
      searchCollection: JSON.stringify([
        { propertyName: property?.apiPropertyName, propertyValue: searchHeaderRef.current.searchValue },
      ]),
    };
  };

  /* istanbul ignore next */
  const loadInitialData = (pageRequest?: IAPIGridRequest): void => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...pageRequest,
      ...getFilterCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...agGrid.filtersApi.getAdvancedSearchFilters(),
    };

    UIStore.setPageLoader(true);
    _aircraftVariationStore
      .getAircraftVariations(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: IAPIPageResponse) => {
        gridState.setGridData(response.results);
        gridState.setPagination(new GridPagination({ ...response }));
        agGrid.filtersApi.gridAdvancedSearchFilterApplied();
      });
  };

  const rightContent = (): ReactNode => {
    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <CustomLinkButton
          variant="contained"
          startIcon={<AddIcon />}
          to="new"
          title="Add Aircraft Variation"
          disabled={gridState.isRowEditing || UIStore.pageLoading}
        />
      </ViewPermission>
    );
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
        // eslint-disable-next-line max-len
        selectInputs={[
          agGridUtilities.createSelectOption(AIRCRAFT_VARIATION_FILTERS, AIRCRAFT_VARIATION_FILTERS.POPULAR_NAME),
        ]}
        onExpandCollapse={agGrid.autoSizeColumns}
        onResetFilterClick={() => {
          agGrid.cancelEditing(0);
          agGrid.filtersApi.resetColumnFilters();
        }}
        onFilterChange={isInitEvent =>
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
        }
        rightContent={rightContent}
        disableControls={gridState.isRowEditing}
      />
      <CustomAgGridReact
        serverPagination={true}
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        paginationData={gridState.pagination}
        onPaginationChange={loadInitialData}
        gridOptions={gridOptions()}
      />
    </>
  );
};

export default inject('aircraftVariationStore', 'sidebarStore')(observer(AircraftVariationV2));
