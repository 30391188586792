import React, { ReactNode } from 'react';
import { VIEW_MODE, BaseUpsertComponent } from '@wings/shared';
import { EDITOR_TYPES, ViewInputControl, IGroupInputControls, IViewInputControl } from '@wings-shared/form-controls';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Observable, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  FlightPlanningServiceStore,
  SettingsStore,
  AircraftModuleSecurity,
  RegistryAssociationDetailModel,
  RegistryAssociationModel,
  FlightPlanningServiceModel,
} from '../../Shared';
import { fields } from './Fields';
import { IClasses, UIStore, withRouter, ViewPermission, SettingsTypeModel } from '@wings-shared/core';
import classNames from 'classnames';
import { Paper, withStyles } from '@material-ui/core';
import { styles } from './RegistryAssociationDetails.style';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { Collapsable } from '@wings-shared/layout';

interface Props {
  classes?: IClasses;
  registryAssociationModel: RegistryAssociationModel;
  registryDetailModel?: RegistryAssociationDetailModel;
  flightPlanningServiceStore?: FlightPlanningServiceStore;
  settingsStore?: SettingsStore;
  viewMode: VIEW_MODE;
  flightPlanningServiceModel: FlightPlanningServiceModel;
  onUpsertDetail: (model: RegistryAssociationDetailModel) => Observable<void>;
}

@inject('flightPlanningServiceStore', 'settingsStore')
@observer
class RegistryAssociationDetails extends BaseUpsertComponent<Props, RegistryAssociationDetailModel> {
  @observable protected registryDetail: RegistryAssociationDetailModel;
  @observable private registrySubHeader: string = 'Departure (B) Destination (L) Destination Alternate (B)';
  @observable private registryDetailId: number = 0;

  constructor(p: Props) {
    super(p, fields);
  }

  /* istanbul ignore next */
  private get hasError(): boolean {
    return this.form.hasError || !this.form.changed;
  }

  componentDidMount() {
    this.loadInitialData();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  /* istanbul ignore next */
  protected get flightPlanningServiceStore(): FlightPlanningServiceStore {
    return this.props.flightPlanningServiceStore as FlightPlanningServiceStore;
  }

  /* istanbul ignore next */
  protected get settingsStore(): SettingsStore {
    return this.props.settingsStore as SettingsStore;
  }

  /* istanbul ignore next */
  private subTitle(departure: string, destination: string, destinationAlternate: string): string {
    const departureCode = departure?.charAt(0);
    const destinationCode = destination?.charAt(0);
    const destinationAlternateCode = destinationAlternate?.charAt(0);

    return `Departure (${departureCode}) 
            Destination (${destinationCode}) 
             Destination Alternate (${destinationAlternateCode})`;
  }

  /* istanbul ignore next */
  private fetchRegistryDetail(id): void {
    //const id = this.props.registryAssociationModel?.customersWithNonStandardRunwayAnalysisRegistryOption;
    this.flightPlanningServiceStore
      .getRegistryAssociationDetail(id.customersWithNonStandardRunwayAnalysisRegistryOptionId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: RegistryAssociationDetailModel) => {
          this.form.set(response);
          this.registryDetailId = response?.id;
          const { departure, destination, destinationAlternate } = response;
          this.registrySubHeader = this.subTitle(departure?.name, destination?.name, destinationAlternate?.name);
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }
  /* istanbul ignore next */
  private loadInitialData() {
    const option = this.props.registryAssociationModel
      ?.customersWithNonStandardRunwayAnalysisRegistryOption as RegistryAssociationDetailModel;

    UIStore.setPageLoader(true);

    forkJoin([ this.props.settingsStore?.getRunwayAnalysisType(), this.props.settingsStore?.getDeliveryPackageType() ])
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {})
      )
      .subscribe({
        next: ([ runwayAnalysisType ]: [SettingsTypeModel[]]) => {
          if (option?.customersWithNonStandardRunwayAnalysisRegistryOptionId) {
            this.fetchRegistryDetail(
              this.props.registryAssociationModel?.customersWithNonStandardRunwayAnalysisRegistryOption
            );
            return;
          }
          const bothValue = runwayAnalysisType.find(x => x.name == 'Both');
          const noneValue = runwayAnalysisType.find(x => x.name == 'None');
          const landingValue = runwayAnalysisType.find(x => x.name == 'Landing');
          this.form.set({
            departure: bothValue,
            destination: landingValue,
            destinationAlternate: bothValue,
            takeoffAlternate: noneValue,
            reclearDestination: noneValue,
            reclearAlternate: noneValue,
            etp: noneValue,
            etops: noneValue,
            pointOfSafeReturn: noneValue,
          });
          UIStore.setPageLoader(false);
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private get groupInputControls(): IGroupInputControls[] {
    return [
      {
        title: 'Runway Analysis',
        inputControls: [
          {
            fieldKey: 'deliveryPackageType',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.deliveryPackageType,
          },
        ],
      },
      {
        title: 'Airport and Analysis Types',
        subTitle: this.registrySubHeader,
        inputControls: [
          {
            fieldKey: 'departure',
            type: EDITOR_TYPES.DROPDOWN,
            isHalfFlex: true,
            options: this.settingsStore?.runwayAnalysisType,
          },
          {
            fieldKey: 'destination',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'destinationAlternate',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'takeoffAlternate',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'reclearDestination',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'reclearAlternate',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etp',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'etops',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
          {
            fieldKey: 'pointOfSafeReturn',
            type: EDITOR_TYPES.DROPDOWN,
            options: this.settingsStore?.runwayAnalysisType,
            isHalfFlex: true,
          },
        ],
      },
    ];
  }

  private get isEditMode(): boolean {
    return this.viewMode === VIEW_MODE.EDIT;
  }

  /* istanbul ignore next */
  public upsertRegistryDetails(): void {
    UIStore.setPageLoader(true);
    const registryDetail: RegistryAssociationDetailModel = new RegistryAssociationDetailModel({
      id: this.registryDetailId,
      customersWithNonStandardRunwayAnalysisRegistryId: this.props.registryAssociationModel.id,
      ...this.form.values(),
    });
    this.flightPlanningServiceStore
      .upsertRegistryAssociationDetail(registryDetail.serialize())
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: RegistryAssociationDetailModel) => {
          this.form.set(response);
          ModalStore.close();
          this.registryDetail = response;
          this.props.onUpsertDetail(response);
        },
        error: (error: AxiosError) => AlertStore.critical(error.message),
      });
  }

  /* istanbul ignore next */
  private get dialogActions(): ReactNode {
    if (this.isEditMode) {
      return (
        <>
          <PrimaryButton
            variant="contained"
            onClick={() => {
              ModalStore.close();
            }}
            disabled={UIStore.pageLoading}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            variant="contained"
            onClick={() => this.upsertRegistryDetails()}
            disabled={this.hasError || UIStore.pageLoading}
          >
            Save
          </PrimaryButton>
        </>
      );
    }

    return (
      <ViewPermission hasPermission={AircraftModuleSecurity.isEditable}>
        <PrimaryButton variant="contained" onClick={() => (this.viewMode = VIEW_MODE.EDIT)}>
          Edit
        </PrimaryButton>
      </ViewPermission>
    );
  }

  /* istanbul ignore next */
  private get dialogContent(): ReactNode {
    const { classes } = this.props as Required<Props>;
    return (
      <Paper className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.flexRow}>
            {this.groupInputControls.map(({ title, inputControls, subTitle }, index) => (
              <Collapsable key={index} title={title} subTitle={subTitle}>
                <div className={classes.flexWrap}>
                  {inputControls
                    .filter(inputControl => !inputControl.isHidden)
                    .map((inputControl: IViewInputControl, index: number) => (
                      <ViewInputControl
                        {...inputControl}
                        key={index}
                        isExists={inputControl.isExists}
                        classes={{
                          flexRow: classNames({
                            [classes.halfFlex]: inputControl.isHalfFlex,
                            [classes.inputControl]: !inputControl.isHalfFlex,
                          }),
                          autoCompleteRoot: classNames({
                            [classes.labelRoot]: inputControl.isFullFlex,
                          }),
                        }}
                        customErrorMessage={inputControl.customErrorMessage}
                        field={this.getField(inputControl.fieldKey || '')}
                        isEditable={this.isEditable}
                        onValueChange={(option, fieldKey) => this.onValueChange(option, inputControl.fieldKey || '')}
                      />
                    ))}
                </div>
              </Collapsable>
            ))}
          </div>
        </div>
      </Paper>
    );
  }

  public render(): ReactNode {
    const { flightPlanningServiceModel, classes } = this.props as Required<Props>;
    const { customerName, customerNumber } = flightPlanningServiceModel;
    return (
      <Dialog
        title={`${customerNumber?.name}-${customerName}-${this.props.registryAssociationModel?.registry}`}
        open={true}
        isLoading={() => UIStore.pageLoading}
        classes={{
          paperSize: classes.paperSize,
          header: classes.headerWrapper,
        }}
        onClose={() => ModalStore.close()}
        dialogContent={() => flightPlanningServiceModel && this.dialogContent}
        dialogActions={() => this.dialogActions}
      />
    );
  }
}

export default withRouter(withStyles(styles)(RegistryAssociationDetails));
export { RegistryAssociationDetails as PureRegistryAssociationDetails };
